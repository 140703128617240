import { format, transports } from "winston";
import { LogLevel } from "./LogLevel";

import "winston-daily-rotate-file";

/**
 * Options used to configure the FileTransport.
 */
export interface FileTransportOptions {
  /**
   * The log level to use for this transport only. If not provided,
   * the global log level is used instead.
   */
  logLevel?: LogLevel;

  /**
   * The file to log to. The file name can include "%DATA%", which will be replaced with
   * the current date.
   */
  logFile?: string;

  /**
   * The maximum size of a log file. Once a file reaches this size, a new file will be created.
   */
  maxSize?: string;

  /**
   * The maximum number of files to keep. This can be a time period, such as 14d, to keep files
   * based on their age.
   */
  maxFiles?: string;
}

const defaultOptions: FileTransportOptions = {
  logFile: "%DATE%.log",
  maxSize: "20m",
  maxFiles: "14d",
};

/**
 * Creates a FileTransport to use with the logger.
 *
 * @param options - Options used to configure the transport.
 * @returns - The transport to pass to the logger.
 */
export function FileTransport(options: FileTransportOptions = {}) {
  options = {
    ...defaultOptions,
    ...options,
  };
  return new transports.DailyRotateFile({
    level: options.logLevel,
    filename: options.logFile,
    maxSize: options.maxSize,
    maxFiles: options.maxFiles,
    format: format.combine(
      format.timestamp({ format: "YYYY-MM-DD HH:mm:ss" }),
      format.printf((info) => {
        const { timestamp, level, context, message, ..._info } = info;
        return `${timestamp} ${level}: ${context} | ${message} | ${JSON.stringify(
          _info
        )}`;
      })
    ),
  });
}
