import { app } from "@electron/remote";
import { webFrame } from "electron";
import settings from "electron-settings";
import { join } from "path";
import VWave from "v-wave";
import { createApp } from "vue";
import TransportStream from "winston-transport";
import { config, name, version } from "../package.json";
import App from "./App.vue";
import { AppSettings } from "./plugins/app-settings/AppSettings";
import { ConsoleTransport } from "./plugins/logger/ConsoleTransport";
import { DataDogTransport } from "./plugins/logger/DataDogTransport";
import { FileTransport } from "./plugins/logger/FileTransport";
import { VueLogger } from "./plugins/logger/VueLogger";
import router from "./router";
import store, { key } from "./store";

const transports: Array<TransportStream> = [
  ConsoleTransport(),
  FileTransport({
    logFile: join(app.getPath("logs"), `${app.getName()}.%DATE%.log`),
  }),
];

if (process.env.NODE_ENV === "production") {
  transports.push(
    new DataDogTransport({
      clientToken: "pub9067cdc7dec1fa4b8ae8077536e08a9f",
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: name,
      version: version,
    })
  );
}

webFrame.setVisualZoomLevelLimits(1, 1);

createApp(App)
  .use(store, key)
  .use(router)
  .use(VWave)
  .use(VueLogger, {
    level: config.logLevel,
    transports,
  })
  .use(AppSettings, {
    mode: "kiosk",
    ...(settings.getSync("config") as Record<string, unknown>),
  })
  .mount("#app");
