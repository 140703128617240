import Transport from "winston-transport";
import { LogLevel } from "./LogLevel";
import { datadogLogs } from "@datadog/browser-logs";
import { pick } from "lodash";

export interface DataDogTransportOptions {
  level?: LogLevel;

  clientToken: string;
  site: string;
  forwardErrorsToLogs: boolean;
  sampleRate?: number;
  service?: string;
  version?: string;
}

export class DataDogTransport extends Transport {
  private readonly logger: typeof datadogLogs.logger;

  constructor(options: DataDogTransportOptions) {
    super(options);
    datadogLogs.init(
      pick(
        options,
        "clientToken",
        "site",
        "forwardErrorsToLogs",
        "sampleRate",
        "service",
        "version"
      )
    );
    this.logger = datadogLogs.logger;
  }

  public get name(): string {
    return "DataDog";
  }

  public log(info: any, next: () => void): void {
    try {
      const { level, message, ...context } = info;
      switch (level) {
        case "error":
          {
            this.logger.error(message, context);
          }
          break;
        case "warn":
          {
            this.logger.warn(message, context);
          }
          break;
        case "info":
          {
            this.logger.info(message, context);
          }
          break;
        case "debug":
          {
            this.logger.debug(message, context);
          }
          break;
        default: {
          this.logger.log(message, context);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      next();
    }
  }
}
